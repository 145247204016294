import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BindingSignaler } from "aurelia-templating-resources";
import { FormatShortDateValueConverter } from 'resources/value-converters/format-short-date';
import { MyHttpApi, DayOfWeek, DiscountGroupByIdResult, DiscountGroupRow, Extra, ExtraGroup, NKDeliveryMethod, NKPaymentType, NKProductType, OrderSendOrderRequest, OrderSendOrderRequestRowExtra, Product, ProductCategory, ProductCategorySalePeriod, ProductPortion, ProductProduct, ProductSubCategory, PublicStoreByIdResponse, StoreProductCatalog } from 'utils/api';
import { Notify } from 'utils/notify';
import { TranslationUtil } from 'utils/translation-util';

/**
 * This function exists to make sure that object equality comparisons work against Dates.
 * Every single Date in this view from this cache.
 */
let dateCacheStore: { [key: number]: Date; } = {};
function dateCache(date: Date) {
  dateCacheStore[date.getTime()] = dateCacheStore[date.getTime()] || new Date(date);
  return dateCacheStore[date.getTime()];
}

let toMinutes = (value: string) => {
  let parts = value.match(/^(\d+):(\d+)/);
  if (!parts) {
    return 0;
  }
  return parseInt(parts[1]) * 60 + parseInt(parts[2]);
};

let toTime = (value: number) => {
  let hours = Math.floor(value / 60);
  let minutes = value % 60;
  return (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
};

let signalToProducts = (productId: number, productType: string, productProductList: ProductProduct[] | undefined, signaler: BindingSignaler) => {
  if (!productProductList) {
    return;
  }
  if (productType === "RECIPE") {
    productProductList.filter(p => p.masterId == productId).forEach(p => signaler.signal("update-products-" + p.productId));
  } else {
    productProductList.filter(p => p.productId == productId).forEach(p => signaler.signal("update-products-" + p.masterId));
  }
  signaler.signal("update-products-" + productId);
};


interface SellableProductCategory {
  productCategory: ProductCategory;
  productCategoryCanBeSold: boolean;
  productCategorySalePeriodList: ProductCategorySalePeriod[];
  productSubCategoryList: SellableProductSubCategory[];
}

interface SellableProductSubCategory {
  productSubCategory?: ProductSubCategory;
  productList: SellableProduct[];
}

interface ShoppingCartProduct {
  product: UIProduct;
  amount: number;
}

interface SessionProduct {
  id: number;
  amount: number;
  selectedSingleExtras: { [key: string]: number; };
  selectedMultiExtras: { [key: string]: string[]; };
  selectedPortionIdx: number;
}

class ShoppingCart {
  public products: ShoppingCartProduct[] = [];
  public store?: PublicStoreByIdResponse;
	public activityTimeout?: any;

  constructor(public signaler: BindingSignaler, public notify: Notify, public deliveryDate: Date) {
  }

  getProductById(shoppingCartProductId?: number) {
    return this.products.find(p => p && p.product.shoppingCartId === shoppingCartProductId);
  }

  changeProduct(product: UIProduct, newAmount: number) {
    if (!product.shoppingCartId) {
      return;
    }
    const shoppingCartProduct = this.getProductById(product.shoppingCartId);
    let numberOfProductsToAdd = newAmount - (shoppingCartProduct?.amount || 0);
    if (shoppingCartProduct?.product.stockAmount && newAmount > shoppingCartProduct?.product.stockAmount) {
      numberOfProductsToAdd = shoppingCartProduct?.product.stockAmount - shoppingCartProduct?.amount;
    }
    if (numberOfProductsToAdd > 0) {
      this.addToCart(product, numberOfProductsToAdd);
    } else {
      this.removeFromCart(product);
    }
    this.products = [...this.products];
    this.signaler.signal("update-products-" + product.id);
    this.saveOrderToSession();
  }

  removeFromCart(product: UIProduct, completelyRemove?: boolean) {
    const shoppingCartProduct = this.getProductById(product.shoppingCartId);
    if (!shoppingCartProduct) {
      return;
    }
    if (shoppingCartProduct.amount == 1 || completelyRemove) {
      let pIndex = this.products.indexOf(shoppingCartProduct);
      product.shoppingCartId = undefined;
      this.products.splice(pIndex, 1);
      this.products = [...this.products];
      signalToProducts(product.id, product.productType, this.store?.productProductList, this.signaler);
      this.saveOrderToSession();
      return;
    }
    shoppingCartProduct.amount--;
    this.products = [...this.products];
    signalToProducts(product.id, product.productType, this.store?.productProductList, this.signaler);
    this.saveOrderToSession();
  }

  addToCart(product: UIProduct, amountToAdd?: number, event?: Event) {
    let newAmount = amountToAdd || 1;
    if (product.hasUnselectedMandatoryExtraGroups) {
      if (event) {
        let el = (<HTMLElement>event.target)?.parentNode?.parentElement?.querySelector('.extra-group-required.no-choice');
        if (!(el as HTMLDivElement)?.classList.contains('extra-group-animation')) {
          (el as HTMLDivElement)?.classList.toggle('extra-group-animation');
          setTimeout(function () {
            (el as HTMLDivElement)?.classList.toggle('extra-group-animation');
          }, 5000);
        }
      }
      return;
    }
    let cartProduct = this.getProductById(product.shoppingCartId);
    if (cartProduct) {
      newAmount += cartProduct.amount;
    }
    if (!product.hasEnoughStock(newAmount)) {
      this.notify.info("store.outOfInventory", { quantity: product.stockAmount });
      return;
    }

    if (cartProduct) {
      cartProduct.amount = newAmount;
    } else {
      product.shoppingCartId = (this.products[this.products.length - 1]?.product.shoppingCartId || 0) + 1 || 1;
      this.products.push({
        product: product,
        amount: newAmount,
      });
    }
    this.products = [...this.products];
    signalToProducts(product.id, product.productType, this.store?.productProductList, this.signaler);
    this.saveOrderToSession();
    if(!this.activityTimeout && this.store?.store.shoppingCartExpireTime) {
      this.activityTimeout = setTimeout(async () => await this.resetCart(), this.store?.store.shoppingCartExpireTime * 1000);
    }
  }

	async resetCart(client?: MyHttpApi)	{
		localStorage.removeItem("sessionProducts");
		localStorage.removeItem("lastOrderChangeTimestamp");
		this.products = [];
    if(client) {
			await client.sessionLogout();
      client.session = undefined;
		}
	}

	resetActive(timeout: number, client: MyHttpApi) {
		clearTimeout(this.activityTimeout);
		this.activityTimeout = setTimeout(async () => { await this.resetCart(client); }, timeout * 1000);
	}

  saveOrderToSession() {
    let sessionProducts: SessionProduct[] = [];
    for (let shoppingCartProduct of this.products) {
      const product = shoppingCartProduct.product;
      sessionProducts.push({
        id: product.id,
        amount: shoppingCartProduct.amount,
        selectedMultiExtras: product.selectedMultiExtras,
        selectedSingleExtras: product.selectedSingleExtras,
        selectedPortionIdx: product.selectedPortionIdx
      });
    }
    localStorage.setItem("sessionProducts", JSON.stringify(sessionProducts));
    localStorage.setItem("lastOrderChangeTimestamp", (new Date()).toString());
  }

  @computedFrom("products")
  get hasMinimumRequiredAmount() {
    return !this.store?.store.minimumOrderAmount || this.store.store.minimumOrderAmount <= this.cartTotal;
  }
  @computedFrom("products")
  get totalQuantity() {
    return this.products.filter(p => p).reduce((total, p) => total + p.amount, 0);
  }

  @computedFrom("products")
  get cartTotal() {
    return this.products.filter(p => p).reduce((total, p) => total + p.amount * p.product.discountPrice, 0);
  }
}

class UIProduct {
  public i18n: I18N;
  public shoppingCart: ShoppingCart;
  public productPortionList: ProductPortion[] = [];
  public productProductList: ProductProduct[] = [];
  public productExtraGroupList: SellableProductExtra[] = [];
  public selectedPortionIdx = 0;
  public discountPercent = 0;
  public shoppingCartId?: number;
  public show = false;
  public productOrderError?: string;
  public selectedSingleExtras: { [key: string]: number; } = {};
  public selectedMultiExtras: { [key: string]: string[]; } = {};
  // * Product-based properties
  public id: number;
  public productType: NKProductType;
  public createTime: Date;
  public modifyTime: Date;
  public deleteTime?: Date;
  public businessUnitId: number;
  public productCategoryId: number;
  public productSubCategoryId?: number;
  public vatCategoryId: number;
  public giftCardProductId?: number;
  public name: string;
  public nameSv?: string;
  public nameEn?: string;
  public weight: number;
  public openPrice: boolean;
  public posProduct?: string;
  public posSalesChannel?: string;
  public storageUnit: number;
  public longDescription?: string;
  public longDescriptionSv?: string;
  public longDescriptionEn?: string;
  public allergy?: string;
  public shortDescription?: string;
  public shortDescriptionSv?: string;
  public shortDescriptionEn?: string;
  public outOfStock?: Date;

  constructor(product: Product, shoppingCart: ShoppingCart, i18n: I18N) {
    this.i18n = i18n;
    this.id = product.id;
    this.productType = product.productType;
    this.createTime = product.createTime;
    this.modifyTime = product.modifyTime;
    this.deleteTime = product.deleteTime;
    this.businessUnitId = product.businessUnitId;
    this.productCategoryId = product.productCategoryId;
    this.productSubCategoryId = product.productSubCategoryId;
    this.vatCategoryId = product.vatCategoryId;
    this.giftCardProductId = product.giftCardProductId;
    this.name = product.name;
    this.nameSv = product.nameSv;
    this.nameEn = product.nameEn;
    this.weight = product.weight;
    this.openPrice = product.openPrice;
    this.posProduct = product.posProduct;
    this.posSalesChannel = product.posSalesChannel;
    this.storageUnit = product.storageUnit;
    this.longDescription = product.longDescription;
    this.longDescriptionSv = product.longDescriptionSv;
    this.longDescriptionEn = product.longDescriptionEn;
    this.allergy = product.allergy;
    this.shortDescription = product.shortDescription;
    this.shortDescriptionSv = product.shortDescriptionSv;
    this.shortDescriptionEn = product.shortDescriptionEn;
    this.outOfStock = product.outOfStock;

    this.shoppingCart = shoppingCart;
  }

  get stockAmount() {
    return this.shoppingCart.store?.theoreticalInventoryStock[this.id];
  }

  @computedFrom("shoppingCart.deliveryDate", "outOfStock")
  get isProductDeliverable() {
    if (this.outOfStock) {
      let outOfStock = this.trimDate(this.outOfStock);
      let deliveryDate = this.trimDate(this.shoppingCart.deliveryDate);
      return outOfStock < deliveryDate;
    }
    return true;
  }

  trimDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  extraGroupInfo(extraGroup: ExtraGroup) {
    if (extraGroup.mandatory && !extraGroup.multiple && !this.selectedSingleExtras[extraGroup.id]) {
      return this.i18n.tr("extra.selectMore", { amount: 1 } as {});
    } else if (extraGroup.mandatory && extraGroup.multiple && extraGroup.minChoiceNumber && this.selectedMultiExtras[extraGroup.id].length < extraGroup.minChoiceNumber) {
      return this.i18n.tr("extra.selectMore", { amount: extraGroup.minChoiceNumber - this.selectedMultiExtras[extraGroup.id].length } as {});
    } else if (extraGroup.mandatory && (!extraGroup.multiple || extraGroup.multiple && extraGroup.maxChoiceNumber && this.selectedMultiExtras[extraGroup.id].length == extraGroup.maxChoiceNumber)) {
      return this.i18n.tr("extra.allSelected");
    }
  }

  isExtraSelected(extraGroupId: number) {
    return (extraGroupId in this.selectedSingleExtras && this.selectedSingleExtras[extraGroupId]) || this.selectedMultiExtras[extraGroupId]?.length > 0;
  }

  @computedFrom("selectedSingleExtras", "selectedMultiExtras", "productExtraGroupList")
  get hasUnselectedMandatoryExtraGroups() {
    return this.productExtraGroupList.length > 0 && !!this.productExtraGroupList.find(eg => eg.extraGroup.mandatory && !(
      (eg.extraGroup.multiple && this.selectedMultiExtras[eg.extraGroup.id].length > 0 &&
        (!eg.extraGroup.minChoiceNumber || eg.extraGroup.minChoiceNumber <= this.selectedMultiExtras[eg.extraGroup.id].length) &&
        (!eg.extraGroup.maxChoiceNumber || eg.extraGroup.maxChoiceNumber >= this.selectedMultiExtras[eg.extraGroup.id].length)) ||
      (!eg.extraGroup.multiple && (eg.extraGroup.id in this.selectedSingleExtras) && this.selectedSingleExtras[eg.extraGroup.id]))
    );
  }

  hasUnselectedMandatoryExtra(eg: SellableProductExtra) {
    return !!(eg.extraGroup.mandatory && !(
      (eg.extraGroup.multiple && this.selectedMultiExtras[eg.extraGroup.id].length > 0 &&
        (!eg.extraGroup.minChoiceNumber || eg.extraGroup.minChoiceNumber <= this.selectedMultiExtras[eg.extraGroup.id].length) &&
        (!eg.extraGroup.maxChoiceNumber || eg.extraGroup.maxChoiceNumber >= this.selectedMultiExtras[eg.extraGroup.id].length)) ||
      (!eg.extraGroup.multiple && (eg.extraGroup.id in this.selectedSingleExtras) && this.selectedSingleExtras[eg.extraGroup.id])));
  }

  toggleDescription(event?: Event) {
    if (event) {
      let target = event.target;
      if (target instanceof HTMLButtonElement || target instanceof HTMLInputElement || target instanceof HTMLLabelElement || target instanceof HTMLAnchorElement || target instanceof HTMLSpanElement) {
        return true;
      }
    }
    this.show = !this.show;
  }

  @computedFrom("shoppingCart.products")
  get orderedAmount() {
    const product = this.shoppingCart.getProductById(this.shoppingCartId);
    return product?.amount || 0;
  }

  @computedFrom("shoppingCart.products")
  get isInCart() {
    return !!this.shoppingCart.getProductById(this.shoppingCartId);
  }

  @computedFrom("selectedPortionIdx")
  get selectedPortion() {
    return this.productPortionList[this.selectedPortionIdx];
  }

  // calculate total portion amount in the shopping cart for products with the same productId, excluding current product with productId
  similarProductPortionAmount(productId: number) {
    let total = 0;
    for (let shoppingCartProduct of this.shoppingCart.products) {
      // skip current product from computation
      if (shoppingCartProduct.product.shoppingCartId == this.shoppingCartId) {
        continue;
      }
      if (shoppingCartProduct.product.productType === "REGULAR") {
        // product not similar
        if (productId !== shoppingCartProduct.product.id) {
          continue;
        }
        total += shoppingCartProduct.amount * shoppingCartProduct.product.selectedPortion?.portion;
      }
      if (shoppingCartProduct.product.productType === "RECIPE") {
        for (let productProduct of shoppingCartProduct.product.productProductList) {
          // product not similar
          if (productId !== productProduct.productId) {
            continue;
          }
          total += shoppingCartProduct.amount * productProduct.amount * productProduct.portion;
        }
      }
    }
    return total;
  }


  hasEnoughStock(amount: number, portion?: number) {
    if (this.productType === "REGULAR") {
      const totalPortionAmount = amount * (portion || this.selectedPortion?.portion) + this.similarProductPortionAmount(this.id);
      return this.stockAmount == undefined || this.stockAmount * this.storageUnit >= totalPortionAmount;
    }

    if (this.productType === "RECIPE") {
      let isInstock = this.stockAmount == undefined || this.stockAmount >= amount;
      for (let pp of this.productProductList) {
        let product = this.shoppingCart.store?.productList.find(p => p.id === pp.productId);
        if (!product || !isInstock) {
          return false;
        }
        let theoreticalInventoryStock = this.shoppingCart.store?.theoreticalInventoryStock[pp.productId];
        if (theoreticalInventoryStock == undefined) {
          return true;
        }
        return theoreticalInventoryStock * product.storageUnit >= amount * pp.portion * pp.amount + this.similarProductPortionAmount(pp.productId);
      }
      return isInstock;
    }

    return true;
  }

  isMultiExtraSelectionDisabled(eg: SellableProductExtra, eid: number) {
    let selectedCount = this.selectedMultiExtras[eg.extraGroup.id]?.length;
    return selectedCount && eg.extraGroup.mandatory && eg.extraGroup.multiple && (eg.extraGroup.maxChoiceNumber && selectedCount >= eg.extraGroup.maxChoiceNumber) && this.selectedMultiExtras[eg.extraGroup.id].indexOf(eid.toString()) < 0;

  }

  @computedFrom("selectedSingleExtras", "selectedMultiExtras")
  get selectedExtras() {
    let extras = [];
    for (const [extraGroupId, extraId] of Object.entries(this.selectedSingleExtras)) {
      let extraGroup = this.productExtraGroupList.find(eg => eg.extraGroup.id.toString() === extraGroupId);
      let extra = extraGroup?.extra.find(e => e.id == extraId);
      if (extra) {
        extras.push(extra);
      }
    }

    for (const [extraGroupId, multiExtras] of Object.entries(this.selectedMultiExtras)) {
      let extraGroup = this.productExtraGroupList.find(eg => eg.extraGroup.id.toString() === extraGroupId);
      for (const extraId of Object.values(multiExtras)) {
        let extra = extraGroup?.extra.find(e => e.id.toString() == extraId);
        if (extra) {
          extras.push(extra);
        }
      }
    }

    return extras;
  }

  /** workaround to get view updated, since aurelia doesn't do deep tracking of changes in array */
  toggleProductOptions() {
    this.selectedSingleExtras = { ...this.selectedSingleExtras };

    if (!this.hasEnoughStock(this.orderedAmount)) {
      if (this.shoppingCartId) {
        let shoppingCartProduct = this.shoppingCart.getProductById(this.shoppingCartId);
        if (shoppingCartProduct) {
          shoppingCartProduct.amount = 1;
        }
      }
    }
    this.shoppingCart.products = [...this.shoppingCart.products];
		this.shoppingCart.signaler.signal("update-products-" + this.id);
  }

  /** precalculate product price */
  @computedFrom("selectedSingleExtras", "selectedMultiExtras", "selectedPortionIdx")
  get price() {
    if (this.productType === "REGULAR") {
      /** get minimum possible price, productPortionsList is sorted by price ASC */
      if (!this.productPortionList[this.selectedPortionIdx]) return 0;
      let productPrice = this.productPortionList[this.selectedPortionIdx].price;

      /** compute possible single extra price */
      for (const [extraGroupId, extraId] of Object.entries(this.selectedSingleExtras)) {
        let extraGroup = this.productExtraGroupList.find(eg => eg.extraGroup.id.toString() === extraGroupId);
        let extra = extraGroup?.extra.find(e => e.id == extraId);
        if (extra) {
          productPrice += extra.extraPrice;
        }
      }

      /** compute possible multi extra price */
      for (const [extraGroupId, extras] of Object.entries(this.selectedMultiExtras)) {
        let extraGroup = this.productExtraGroupList.find(eg => eg.extraGroup.id.toString() === extraGroupId);
        for (const extraId of Object.values(extras)) {
          let extra = extraGroup?.extra.find(e => e.id.toString() == extraId);
          if (extra) {
            productPrice += extra.extraPrice;
          }
        }
      }
      return productPrice;
    }
    else if (this.productType === "RECIPE") {
      /** calculate product price based on subproducts price and their amount */
      return this.productProductList.reduce((total, a) => total + a.amount * a.price, 0);
    }
    else {
      return 0;
    }
  }

  calculateDiscount(price: number, discountPercent: number) {
    if (!this.discountPercent) {
      return price;
    }
    return price - Math.round(price * discountPercent + 0.2e-2) / 100;
  }

  @computedFrom("price", "discountPercent")
  get discountPrice() {
    return this.calculateDiscount(this.price, this.discountPercent);
  }

  @computedFrom("discountPercent", "shoppingCart.products")
  get productTotalPrice() {
    const product = this.shoppingCart.getProductById(this.shoppingCartId);
    return (product?.amount || 1) * this.discountPrice;
  }
}


interface SellableProduct {
  product: UIProduct;
  productProducts?: ProductProduct[];
  productCategory: ProductCategory;
  productSubCategory?: ProductSubCategory;
  storeProductCatalog: StoreProductCatalog;
  extraGroup: SellableProductExtra[];
  idx: number;
}

interface SellableProductExtra {
  extraGroup: ExtraGroup;
  extra: Extra[],
}

interface OrderRow {
  product: UIProduct;
  amount: 0;
  portion: 1;
}

interface ThisWeek {
  date: Date;
  dow: DayOfWeek;
  hours: { openTime: string, closeTime: string, description?: string, descriptionEn?: string, descriptionSv?: string; }[],
}

const dayOfWeekList: DayOfWeek[] = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

@autoinject
export class StoreListIndex {
  /* value is quantity of product at that index + the quantity of extras which are associated to that product. */
  @observable({ changeHandler: "extraError" })
  private deliveryMethod: NKDeliveryMethod = "FETCH";
  @observable({ changeHandler: "updateDiscountGroups" })
  private sellableProductList: SellableProduct[] = [];

  /** Combination of all discount groups user is entitled to (by both email and discount code) */
  @observable({ changeHandler: "updateDiscountGroups" })
  private discountGroupList: DiscountGroupByIdResult[] = [];

  @observable
  private shouldRestoreCart = false;
  /** by product id to applicable discount group row */
  private discountGroupRowByProductId: { [key: number]: DiscountGroupRow; } = {};

  private orderErrors: { [index: string]: string; } = {};
  private productsToRestore: SessionProduct[] = [];
  private search?: string;
  private readyById?: string;
  private store?: PublicStoreByIdResponse;
  private storePoller?: NodeJS.Timer;
  @observable
  private deliveryDate = new Date();
  private shoppingCart = new ShoppingCart(this.signaler, this.notify, this.deliveryDate);
  private paymentType: NKPaymentType = "OnSite";
  private tableNumber?: string;
  private freeText?: string;
  /* user info */
  private firstName?: string;
  private lastName?: string;
  private email?: string;
  private phone?: string;
  private discountCode?: string;

  private adjustDesiredReadyTimeModal = false;
  private adjustDesiredReadyTimeModalContinuation?: () => void;
  private confirmModal = false;
  private searchModal = false;
  @observable
  private cartRestoreModal = false;
  private adjustTableNumberModal = false;
  private adjustTableNumberModalContinuation?: () => void;

  private selectedProductCategoryId?: number;
  private moveAnimation = "initial";
  private sendOrderWaiting = false;
  private privacyPolicy = "";
  private termsAndConditions = "";
  @observable
  private privacyPolicyAccepted = false;
  private showPrivacyPolicyModal = false;
  private showTermsAndConditionsModal = false;
  private privacyPolicyDate = "11.12.2023";
  constructor(private bindingSignaler: BindingSignaler, private i18n: I18N, private client: MyHttpApi, private router: Router, private notify: Notify, private translationUtil: TranslationUtil, private signaler: BindingSignaler) {
  }

  async attached() {
    document.addEventListener("scroll", this.scrollTrigger.bind(this));
		if(this.store?.store.shoppingCartExpireTime) {
      document.addEventListener("mousemove", this.resetActive.bind(this));
      document.addEventListener("touchmove", this.resetActive.bind(this));
			this.resetActive();
    }
		this.privacyPolicyAccepted = localStorage.getItem(`policyAndTermsAccepted-${this.privacyPolicyDate}`) == "1";
  }

	privacyPolicyAcceptedChanged(newValue: boolean, oldValue: boolean) {
		if(oldValue != undefined) {
			localStorage.setItem(`policyAndTermsAccepted-${this.privacyPolicyDate}`, newValue ? "1" : "0");
		}
	}

	async openPrivacyPolicy() {
		this.showPrivacyPolicyModal = true;
		this.privacyPolicy = await (await fetch(`/register-fi.html`)).text();
	}

	async openTermsAndConditions() {
		this.showTermsAndConditionsModal = true;
	}

	acceptPolicyAndTerms() {
		this.privacyPolicyAccepted = true;
		localStorage.setItem(`policyAndTermsAccepted-${this.privacyPolicyDate}`, "1");
		this.showPrivacyPolicyModal = false;
		this.showTermsAndConditionsModal = false;
	}

  resetActive() {
    if(this.shoppingCart.products.length && this.store?.store.shoppingCartExpireTime) {
      this.shoppingCart.resetActive(this.store?.store.shoppingCartExpireTime, this.client);
    }
  }

  detached() {
    document.removeEventListener("scroll", this.scrollTrigger.bind(this));
    if(this.store?.store.shoppingCartExpireTime) {
      document.removeEventListener("mousemove", this.resetActive.bind(this));
      document.removeEventListener("touchmove", this.resetActive.bind(this));
    }
  }

  deliveryDateChanged(newDate: Date, oldDate: Date) {
    if (!this.shoppingCart) {
      return;
    }
    this.shoppingCart.deliveryDate = newDate;
    for (let p of this.store?.productList || []) {
      this.shoppingCart.signaler.signal("update-products-" + p.id);
    }
  }

  scrollTrigger(event: Event) {
    if (window.scrollY > 250) {
      this.moveAnimation = "move";
    } else if (this.moveAnimation == "move") {
      this.moveAnimation = "original";
    }
  }

  shouldRestoreCartChanged(newValue: boolean, oldValue: boolean) {
    if (newValue) {
      this.restoreCartFromSession();
    }
  }

  cartRestoreModalChanged(newValue: boolean, oldValue: boolean) {
    if (!newValue && oldValue) {
      localStorage.removeItem("sessionProducts");
      localStorage.removeItem("lastOrderChangeTimestamp");
    }
  }

  toggleSearchModal() {
    this.searchModal = !this.searchModal;
  }

  scrollToCategory(cId: number) {
    let category = document.getElementById("category-" + cId);
    if (!category) {
      return;
    }
    this.searchModal = false;
    category.scrollIntoView({ behavior: 'smooth' });
  }

  isCategoryEmpty(categoryId: number, sellableProductCategoryList: SellableProductCategory[]) {
    return !this.sellableProductCategoryList.find(spc => spc.productSubCategoryList.find(pcs => pcs.productList.find(p => p.productCategory.id == categoryId)));
  }

  @computedFrom("client.session")
  get fillUserDataObserver() {
    if (this.client.session) {
      this.client.actorSelf()
        .then(self => {
        this.firstName = self.firstName;
        this.lastName = self.lastName;
        this.email = self.email;
        this.phone = self.phone;
      }).catch(_ => { /*no-op*/});
    }
    return "";
  }


  async activate(params: { tn: string; }) {
    this.notify.loginCallback = () => { /* no action */ };
    this.store = this.notify.store;
    this.shoppingCart.store = this.store;
    if (!this.store || this.store.store.storeType !== 'POS') {
      this.router.navigateToRoute("/", params);
      return;
    }
    this.sellableProductList = this.deliveryDateList.length ? this.computeSellableProductList(this.store) : [];
    if (this.store.store.autoSelectFirstPossibleTime !== "MANUAL") {
      this.deliveryDate = this.deliveryDateList[0]?.id || dateCache(this.store.today);
      this.readyById = this.readyByList[0]?.id;
    } else {
      this.deliveryDate = dateCache(this.store.today);
    }

    await this.maybeFetchDiscounts(false, false);

    if (this.store.store.paymentType.indexOf("PrePaid") !== -1) {
      this.paymentType = "PrePaid";
    }
    this.deliveryMethod = this.store.store.deliveryMethod;

    if (params.tn) {
      localStorage.setItem("table-number", params.tn);
      localStorage.setItem("tableNumberChangeTimestamp", (new Date()).toString());
    }
    if (this.store.store.tableNumber !== 'NONE') {
      let tableNumberChangeDateString = localStorage.getItem("tableNumberChangeTimestamp");

      if (tableNumberChangeDateString) {
        let tableNumberChangeDate = new Date(tableNumberChangeDateString);
        let now = new Date();
        let differenceValue = (now.getTime() - tableNumberChangeDate.getTime()) / (1000 * 60);
        if (differenceValue <= 120) {
          this.tableNumber = localStorage.getItem("table-number") ?? '';
        }
      }
    }

    if (this.store.store.tableNumber === "REQUIRED" && !this.tableNumber) {
      this.adjustTableNumberModal = true;
    }

    this.startPollStore();
    let shoppingCartProductsString = localStorage.getItem("sessionProducts");
    if (shoppingCartProductsString) {
      this.productsToRestore = <SessionProduct[]>JSON.parse(shoppingCartProductsString);
    }
    this.maybeRestoreCartFromSession();
    await this.maybeFetchDiscounts(false, false);
  }

  /**
   * When user inputs a discount code, try to find and apply these discounts.
   */
  async maybeFetchDiscounts(discountCodeFeedback: boolean, emailFeedback: boolean) {
    let tmp: { [key: number]: DiscountGroupByIdResult; } = {};

    if (this.store) {
      let discountGroupList = await this.client.publicGeneralDiscounts({ businessUnitId: this.store.businessUnit.id });
      for (let row of discountGroupList) {
        tmp[row.discountGroup.id] = row;
      }
      if (this.email) {
        let discountGroupList = await this.client.publicDiscountsByEmail({ businessUnitId: this.store.businessUnit.id, email: this.email });
        for (let row of discountGroupList) {
          tmp[row.discountGroup.id] = row;
        }
        if (emailFeedback) {
          this.notify.info(discountGroupList.length ? "store.discountGroupsFound" : "store.discountGroupsNotFound");
        } else if (discountGroupList.length) {
          this.notify.info("store.discountGroupsFound");
        }
      }
      if (this.discountCode) {
        let discountGroupList = await this.client.publicDiscountsByCode({ businessUnitId: this.store.businessUnit.id, code: this.discountCode });
        for (let row of discountGroupList) {
          tmp[row.discountGroup.id] = row;
        }
        if (discountCodeFeedback) {
          this.notify.info(discountGroupList.length ? "store.discountGroupsFound" : "store.discountGroupsNotFound");
        }
      }
    }

    this.discountGroupList = Object.values(tmp);
  }

  @computedFrom("discountGroupList")
  get hasOnlyGeneralDiscount() {
    return !this.discountGroupList.find(dg => !dg.discountGroup.isGeneral);
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }

  localizeBrand(string: string | undefined, _language: string) {
    if (!string) {
      return "";
    } else {
      return string.replace(/\{([^}]+)\}/g, (_match, token) => this.i18n.tr(token, {}));
    }
  }

  /** Compute the discount % that shuold be applied on per-product basis into discountGroups */
  updateDiscountGroups() {
    this.discountGroupRowByProductId = {};
    for (let product of this.sellableProductList) {
      let discountGroupRow: DiscountGroupRow | undefined = undefined;
      let discountPercent = 0;
      let discountSpecificity = 0;

      for (let dg of this.discountGroupList) {
        for (let row of dg.row) {
          /* Does this discount group match? */
          if (row.productId && product.product.id !== row.productId) {
            continue;
          }
          if (row.productCategoryId && product.product.productCategoryId !== row.productCategoryId) {
            continue;
          }

          /* How specific is this rule? */
          let specificity = (row.productId ? 1 : 0) << 2 | (row.productCategoryId ? 1 : 0);

          if (specificity > discountSpecificity) {
            discountPercent = row.value;
            discountGroupRow = row;
            discountSpecificity = specificity;
          } else if (specificity === discountSpecificity && discountPercent < row.value) {
            discountPercent = Math.max(discountPercent, row.value);
            discountGroupRow = row;
          } else {
            /* discount less specific or worse than what we already have, ignore */
          }
        }
      }

      if (discountGroupRow) {
        product.product.discountPercent = discountGroupRow.value;
        this.discountGroupRowByProductId[product.product.id] = discountGroupRow;
      } else {
        product.product.discountPercent = 0;
      }
    }

    this.refresh();
  }

  @computedFrom("store", "deliveryMethod", "deliveryDate", "deliveryDateList")
  get closed() {
    if (!this.store) {
      return undefined;
    }

    if (this.store.store.storeIsClosedByDefault && !this.deliveryDateList.length) {
      // Kaupalla on päällä valinta "Kauppa on auki vain valituissa päiväkohtaisissa aikaikkunoissa" eikä sillä ole yhtään validia aukioloaikaa tulevaisuudessa.
      return "order.shopIsClosedByDefaultAndNoDeliveryDate";
    }

    if (!this.store.store.storeIsClosedByDefault && !this.deliveryDateList.length) {
      // Kaupalla ei ole päällä valinta "Kauppa on auki vain valituissa päiväkohtaisissa aikaikkunoissa" eikä sillä ole yhtään validia aukioloaikaa tulevaisuudessa.
      return "order.shopIsClosedButOpensInFuture";
    }

    if (this.store.businessUnit.openingDate > this.store.today) {
      // Yksikön asetuksiin on merkattu avaamispäivämäärä on tulevaisuudessa.
      return "order.shopIsClosedButOpensInFuture";
    }

    if (this.store.businessUnit.closingDate && this.store.businessUnit.closingDate < this.store.today) {
      // Yksikön asetuksiin on merkattu sulkemispäivämäärä joka on menneisyydessä.
      return "order.shopIsClosed";
    }

    if (this.store.store.closed && this.store.store.closed >= this.deliveryDate) {
      // Kauppa on suljettu tilapäisesti
      return "order.shopIsClosedTemporarily";
    }

    if (this.deliveryMethod === "ON_SITE" && !this.storeIsOpenAt(this.store.today, this.store.time)) {
      return "order.shopIsClosedOnSite";
    }


    return undefined;
  }

  createUIProduct(storeProduct: Product, store: PublicStoreByIdResponse) {
    let product = new UIProduct(storeProduct, this.shoppingCart, this.i18n);
    product.productPortionList = store.productPortionList.filter(pp => pp.productId == product.id);
    product.productProductList = store.productProductList.filter(pp => pp.masterId == product.id);
    product.discountPercent = this.discountGroupRowByProductId[product.id]?.value;
    for (let eg of store.productExtraGroupList) {
      let extraGroup = store.extraGroupList.find(seg => seg.id === eg.extraGroupId && eg.productId === product.id);
      if (!extraGroup) {
        continue;
      }
      let extra = store.extraList.filter(e => store.extraExtraGroupList.find(eeg => eeg.extraId == e.id && eeg.extraGroupId == extraGroup?.id));
      product.productExtraGroupList.push({ extra: extra, extraGroup: extraGroup });
      if (extraGroup.multiple) {
        product.selectedMultiExtras[extraGroup.id.toString()] = [];
      }
    }
    return product;
  }

  getProductById(pid: number) {
    return this.sellableProductList.find(sp => sp.product.id == pid);
  }

  restoreCartFromSession() {
    this.shouldRestoreCart = false;
    this.cartRestoreModal = false;
    if (!this.productsToRestore.length) {
      return;
    }
    for (let shoppingCartProduct of this.productsToRestore) {
      let sellableProduct = this.sellableProductList.find(sp => sp.product.id == shoppingCartProduct.id);
      if (!sellableProduct) {
        return;
      }
      if (sellableProduct.product.shoppingCartId) {
        sellableProduct = this.copyProduct(sellableProduct.idx);
        if (!sellableProduct) {
          return;
        }
      }
      sellableProduct.product.selectedPortionIdx = shoppingCartProduct.selectedPortionIdx;
      sellableProduct.product.selectedMultiExtras = shoppingCartProduct.selectedMultiExtras;
      sellableProduct.product.selectedSingleExtras = shoppingCartProduct.selectedSingleExtras;
      this.shoppingCart.addToCart(sellableProduct.product, shoppingCartProduct.amount);
    }
  }

  maybeRestoreCartFromSession() {
    if (this.shoppingCart.totalQuantity > 0 || !this.productsToRestore.length) {
      return;
    }

    let orderDateString = localStorage.getItem("lastOrderChangeTimestamp");
    if (orderDateString) {
      let orderDate = new Date(orderDateString);
      let now = new Date();
      let differenceValue = (now.getTime() - orderDate.getTime()) / (1000 * 60);
      if (differenceValue <= 15) {
        this.restoreCartFromSession();
        return;
      }
    }

    this.cartRestoreModal = true;
  }

  cancelShoppingCartRestore() {
    this.shouldRestoreCart = false;
    this.cartRestoreModal = false;
    localStorage.removeItem("sessionProducts");
  }

  computeSellableProductList(store: PublicStoreByIdResponse) {
    const spcMap = new Map<number, StoreProductCatalog>();
    for (let spc of store.storeProductCatalogList) {
      spcMap.set(spc.productId, spc);
    }
    const pcMap = MyHttpApi.toHash(store.productCategoryList);
    const pscMap = MyHttpApi.toHash(store.productSubCategoryList);
    const productList = store.productList.map(product => this.createUIProduct(product, store));

    productList.sort((a, b) => {
      let aPC = pcMap[a.productCategoryId] || { weight: 0, name: "" };
      let bPC = pcMap[b.productCategoryId] || { weight: 0, name: "" };
      if (aPC.weight < bPC.weight) {
        return -1;
      } else if (aPC.weight > bPC.weight) {
        return 1;
      } else if (aPC.name < bPC.name) {
        return -1;
      } else if (aPC.name > bPC.name) {
        return 1;
      }

      let aPSC = pscMap[a.productSubCategoryId || 0] || { weight: 0, name: "" };
      let bPSC = pscMap[b.productSubCategoryId || 0] || { weight: 0, name: "" };
      if (aPSC.weight < bPSC.weight) {
        return -1;
      } else if (aPSC.weight > bPSC.weight) {
        return 1;
      } else if (aPSC.name < bPSC.name) {
        return -1;
      } else if (aPSC.name > bPSC.name) {
        return 1;
      } else if (a.weight < b.weight) {
        return -1;
      } else if (a.weight > b.weight) {
        return 1;
      } else if (store.store.productSorting == "NAME") {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      } else if (store.store.productSorting == "PRICE") {

        if (!(a.price && b.price)) {
          return 0;
        }

        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        } else {
          return 0;
        }

      } else {
        return 0;
      }
    });
    let list: SellableProduct[] = [];
    let idx = 0;
    for (let product of productList) {
      /* Confirm that we have PC */
      let productCategory = pcMap[product.productCategoryId];
      if (!productCategory) {
        continue;
      }

      /* And PSC, if defined */
      let productSubCategory = pscMap[product.productSubCategoryId || 0];
      if (product.productSubCategoryId && !productSubCategory) {
        continue;
      }

      let storeProductCatalog = spcMap.get(product.id);
      if (!storeProductCatalog) {
        continue;
      }

      let extraGroup = store.productExtraGroupList.filter(peg => peg.productId === product.id)
        .map(peg => {
          /* Find the extraGroup */
          let actualExtraGroup = store.extraGroupList.find(eg => eg.id === peg.extraGroupId)!;
          /* Follow the extra-extragroup mapping, then select the extras */
          let extra = store.extraExtraGroupList.filter(eeg => eeg.extraGroupId === peg.extraGroupId)
            .map(eeg => store.extraList.find(e => e.id === eeg.extraId)!);
          return {
            extraGroup: actualExtraGroup,
            extra,
          };
        });

      /* Server returns us qty free based on inventory date and known orders since inventory date */
      list.push({
        product,
        productCategory,
        productSubCategory,
        storeProductCatalog,
        extraGroup,
        idx: idx++,
      });
    }
    return list;
  }

  deactivate() {
    this.endPollStore();
  }

  startPollStore() {
    this.storePoller = setInterval(async () => {
      this.store = await this.client.publicStoreById({ id: this.store?.store.id });
      this.shoppingCart.store = this.store;
    }, 60000);
  }

  endPollStore() {
    if (this.storePoller) {
      clearInterval(this.storePoller);
      this.storePoller = undefined;
    }
  }

  adjustDesiredReadyTime(continuation: () => void) {
    this.adjustDesiredReadyTimeModal = !this.adjustDesiredReadyTimeModal;
    let obj = this.adjustDesiredReadyTimeModalContinuation;
    this.adjustDesiredReadyTimeModalContinuation = continuation;
    if (!this.adjustDesiredReadyTimeModal && obj) {
      obj();
    }
    this.shoppingCart.deliveryDate = this.deliveryDate;
    for (const product of this.shoppingCart.products) {
      this.shoppingCart.signaler.signal("update-products-" + product.product.id);
    }
  }

  adjustTableNumber(continuation: () => void) {
    if (this.tableNumber) {
      localStorage.setItem("table-number", this.tableNumber);
      localStorage.setItem("tableNumberChangeTimestamp", (new Date()).toString());
    }
    this.adjustTableNumberModal = !this.adjustTableNumberModal;
    let obj = this.adjustTableNumberModalContinuation;
    this.adjustTableNumberModalContinuation = continuation;
    if (!this.adjustTableNumberModal && obj) {
      obj();
    }
  }

  @computedFrom('maxAvailableOrderDate', 'store')
  get deliveryDateList() {
    if (!this.store) {
      return [];
    }

    let list: { id: Date, name: string; }[] = [];
    if (this.store.store.storeIsClosedByDefault) {
      list = this.deliveryDateOverrides();
    } else {
      list = this.deliveryDateOpenings();
    }
    if (list.length && list[0].id.getTime() == this.store.today.getTime()) {
      list[0].name = "Tänään";
    }
    return list;
  }

  @computedFrom("store")
  get minimumOrderDays() {
    if (!this.store) {
      return 0;
    }
    return this.store.store.minimumOrderDays + (this.store.store.minimumOrderDayChanges > 0 && toMinutes(this.store.time) >= this.store.store.minimumOrderDayChanges * 60 ? 1 : 0);
  }

  deliveryDateOverrides() {
    if (!this.store) {
      return [];
    }

    let start = new Date(this.store.today);
    start.setUTCDate(start.getUTCDate() + this.minimumOrderDays);
    let maxAvailableOrderDate = new Date(this.store.now);
    maxAvailableOrderDate.setUTCDate(maxAvailableOrderDate.getUTCDate() + this.store.store.availableOrderDays);

    let list: { id: Date, name: string; }[] = [];
    let dateConverter = new FormatShortDateValueConverter();
    while (start < maxAvailableOrderDate) {
      let earliestPossibleTime = 0;
      if (start.getTime() == this.store.today.getTime()) {
        /* for today, earliest possible time is ordering right now */
        earliestPossibleTime = toMinutes(this.store.time) + this.store.store.preparingTime;
      }
      // * From today, get future or currently open hours
      if (this.store.storeHourOverride.find(p => p.date.getTime() == start.getTime()
        && (
          toMinutes(p.openingTime) >= earliestPossibleTime) ||
        (toMinutes(p.closingTime) > earliestPossibleTime && toMinutes(p.openingTime) < earliestPossibleTime)
      )
      ) {
        list.push({ id: dateCache(start), name: dateConverter.toView(start, "noTime") });
      }
      start.setUTCDate(start.getUTCDate() + 1);
    }
    return list;
  }

  deliveryDateOpenings() {
    if (!this.store) {
      return [];
    }

    let start = new Date(this.store.today);
    start.setUTCDate(start.getUTCDate() + this.minimumOrderDays);
    let maxAvailableOrderDate = new Date(this.store.now);
    maxAvailableOrderDate.setUTCDate(maxAvailableOrderDate.getUTCDate() + this.store.store.availableOrderDays);

    let list: { id: Date, name: string; }[] = [];
    let dateConverter = new FormatShortDateValueConverter();
    while (start < maxAvailableOrderDate) {
      let earliestPossibleTime = 0;
      if (start.getTime() == this.store.today.getTime()) {
        /* for today, earliest possible time is ordering right now */
        earliestPossibleTime = toMinutes(this.store.time) + this.store.store.preparingTime;
      }
      for (let hour = 0; hour < 24 * 60; hour += 15) {
        if (hour < earliestPossibleTime) {
          continue;
        }
        if (this.storeIsOpenAt(start, toTime(hour))) {
          list.push({ id: dateCache(start), name: dateConverter.toView(start, "noTime") });
          break;
        }
      }
      start.setUTCDate(start.getUTCDate() + 1);
    }

    return list;
  }

  @computedFrom("store", "readyByList", "readyById")
  get readyBy() {
    if (!this.store) {
      return undefined;
    }
    return this.readyByList.find(p => this.readyById === p.id)?.time;
  }

  @computedFrom("store", "deliveryDate")
  get readyByList() {
    if (!this.store) {
      return [];
    }

    if (this.store.store.storeIsClosedByDefault) {
      return this.readyByOverrides();
    } else {
      return this.readyByOpenings();
    }
  }

  @computedFrom("readyByList", "readyById")
  get readyByName() {
    return this.readyByList.find(p => this.readyById === p.id)?.name;
  }

  readyByOverrides() {
    if (!this.store) {
      return [];
    }

    let earliestPossibleTime = 0;
    if (this.deliveryDate.getTime() == this.store.today.getTime()) {
      /* for today, earliest possible time is ordering right now */
      earliestPossibleTime = toMinutes(this.store.time) + this.store.store.preparingTime;
    }

    let options = this.store.storeHourOverride.filter(p => p.date.getTime() === this.deliveryDate.getTime() && toMinutes(p.openingTime) >= earliestPossibleTime);
    return options.map(sho => {
      let name = this.translationUtil.localized(sho, "description", this.lang);
      return {
        id: sho.id.toString(),
        name: (name ? name + ": " : "") + toTime(toMinutes(sho.openingTime)) + (sho.openingTime !== sho.closingTime ? "–" + toTime(toMinutes(sho.closingTime)) : ""),
        time: toTime(toMinutes(sho.openingTime)),
        description: sho.description,
        descriptionEn: sho.descriptionEn,
        descriptionSv: sho.descriptionSv,
      };
    });
  }

  readyByOpenings() {
    if (!this.store) {
      return [];
    }

    let endTime = 24 * 60;
    let earliestPossibleTime = 0;
    if (this.deliveryDate.getTime() == this.store.today.getTime()) {
      /* for today, earliest possible time is ordering right now */
      earliestPossibleTime = toMinutes(this.store.time);
    }

    let list: { id: string, name: string, time: string; }[] = [];
    for (let i = 0; i <= endTime - this.store.store.preparingTime; i += 15) {
      if (i < earliestPossibleTime) {
        continue;
      }
      /* This is the desired delivery time, and store must also be open at that time */
      let time = toTime(i + this.store.store.preparingTime);
      if (!this.storeIsOpenAt(this.deliveryDate, time)) {
        continue;
      }
      list.push({
        id: time,
        name: time,
        time: time,
      });
    }
    if (list.length && this.deliveryDate.getTime() == this.store.today.getTime()) {
      list[0].name = "Niin pian kuin mahdollista, " + list[0].name;
    }

    if (this.store.store.skipTime && list.length) {
      this.readyById = list[0].id;
    } else if (list.length && !list.find(p => p.id === this.readyById)) {
      if (this.deliveryDate.getTime() == this.store.today.getTime()) {
        let time = toMinutes(this.store.time);
        time -= time % 15;
        let potentialDeliveryTime = toTime(time + this.store.store.preparingTime);
        this.readyById = list.find(l => l.id === potentialDeliveryTime)?.id;
      } else {
        this.readyById = undefined;
      }
    }

    return list;
  }

  @computedFrom("store")
  get logoUrl() {
    if (!this.store) {
      return "";
    }
    return this.client.publicLogoImageUrl({
      id: this.store.businessUnitBrand.businessUnitId, modifyTime: this.store.businessUnitBrand.modifyTime,
    });
  }

  @computedFrom("store", "deliveryDate", "readyById")
  get thisWeek() {
    if (!this.store) {
      return [];
    }

    if (this.store.store.storeIsClosedByDefault) {
      return this.thisWeekOverrides();
    } else {
      return this.thisWeekOpenings();
    }
  }

  /**
   * Return open periods in the behavior where is store is always closed except for particular occasions
   * 
   * @returns list of open periods based on specific individual overrides (Riviera type case where place is open for showings only)
   */
  thisWeekOverrides() {
    if (!this.store) {
      return [];
    }

    let daysOfWeek: DayOfWeek[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

    let thisWeek: ThisWeek[] = [];
    for (let dateOffset = 0; dateOffset < Math.min(7, this.store.store.availableOrderDays); dateOffset++) {
      /* Find if a given weekday is in the future or past relative to order date */
      let wantedDate = new Date(this.store.today);
      wantedDate.setUTCDate(wantedDate.getUTCDate() + dateOffset);
      let dow = daysOfWeek[(wantedDate.getUTCDay() + 6) % 7];

      let obj: ThisWeek = {
        date: dateCache(wantedDate),
        dow: dow,
        hours: [],
      };

      let periods = this.store.storeHourOverride.filter(s => s.date.getTime() === obj.date.getTime());
      for (let p of periods) {
        obj.hours.push({
          openTime: p.openingTime,
          closeTime: p.closingTime,
          description: p.description,
          descriptionEn: p.descriptionEn,
          descriptionSv: p.descriptionSv,
        });
      }

      if (obj.hours.length) {
        thisWeek.push(obj);
      }
    }

    return thisWeek;
  }

  /**
   * Return store open periods in case where store is normally open.
   * 
   * @returns list of periods at 15 minute granularity as discovered from various rules/datastructures
   */
  thisWeekOpenings() {
    if (!this.store) {
      return [];
    }
    let daysOfWeek: DayOfWeek[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

    let thisWeek: ThisWeek[] = [];
    for (let dateOffset = this.minimumOrderDays; dateOffset < Math.min(7, this.store.store.availableOrderDays); dateOffset++) {
      /* Find if a given weekday is in the future or past relative to order date */
      let wantedDate = new Date(this.store.today);
      wantedDate.setUTCDate(wantedDate.getUTCDate() + dateOffset);
      let dow = daysOfWeek[(wantedDate.getUTCDay() + 6) % 7];

      let obj: ThisWeek = {
        date: dateCache(wantedDate),
        dow: dow,
        hours: [],
      };

      let hours = 0;
      while (hours < 24 * 60) {
        if (this.storeIsOpenAt(wantedDate, toTime(hours))) {
          let openTime = toTime(hours);
          hours += 15;

          while (hours < 24 * 60 && this.storeIsOpenAt(wantedDate, toTime(hours))) {
            hours += 15;
          }

          let closeTime = toTime(hours - 15);
          obj.hours.push({
            openTime,
            closeTime,
          });
        } else {
          hours += 15;
        }
      }

      thisWeek.push(obj);
    }
    return thisWeek;
  }

  /**
   * Tell if store & bu is open at specific date and time by iterating all datastructures.
   * The rules for being open are:
   * 
   * - bu must be open in period
   * - bu must not be closed in period
   * - store must not be closed
   * - if there is day-specific override, we are within that range
   * - if there is day of week-based default, we are within that range
   * - special range from midnight to mindight means closed
   * - otherwise, store is open.
   * 
   * FIXME: this needs unfucking. Better implementation might return the day ranges for each day,
   * and the open period list would be built based on that, for instance.
   */
  storeIsOpenAt(date: Date, time: string) {
    if (!this.store) {
      return false;
    }

    if (this.store.businessUnit.openingDate > date) {
      return false;
    }
    if (this.store.businessUnit.closingDate && this.store.businessUnit.closingDate < date) {
      return false;
    }
    if (this.store.store.closed && this.store.store.closed > date) {
      return false;
    }

    let dayOfWeek = dayOfWeekList[date.getUTCDay()];
    if (time.length < 6) {
      time += ":00";
    }

    let shoList = this.store.storeHourOverride.filter(p => p.date.getTime() === date.getTime());
    if (shoList.length) {
      let buho = shoList.find(p => p.openingTime <= time && p.closingTime >= time);
      if (!buho) {
        return false;
      }
      /* closed */
      if (buho.openingTime == "00:00:00" && buho.closingTime == "00:00:00") {
        return false;
      }
    } else if (this.store.storeHour.find(p => p.dayOfWeek == dayOfWeek)) {
      let hours = this.store.storeHour.find(p => p.dayOfWeek === dayOfWeek && p.openingTime <= time && p.closingTime >= time);
      if (!hours) {
        return false;
      }
      /* closed */
      if (hours.openingTime == "00:00:00" && hours.closingTime == "00:00:00") {
        return false;
      }
    }

    return true;
  }

  /**
   * Determine if product category can be sold given the date and time.
   * If there is no restriction, then the product category is always available.
   * 
   * @param pc 
   * @param date 
   * @param time 
   * @returns true when sellable
   */
  calculateProductCategoryCanBeSold(pc: ProductCategory, date: Date, time: string) {
    if (!this.store) {
      return false;
    }
    let dayOfWeek = dayOfWeekList[date.getUTCDay()];
    if (time.length < 6) {
      time += ":00";
    }
    let salesLimit = this.store.productCategorySalePeriodList.filter(p => p.productCategoryId == pc.id);
    if (salesLimit.length) {
      let pcsp = salesLimit.find(p => p.dayOfWeek == dayOfWeek && p.startTime <= time && p.endTime >= time);
      if (!pcsp) {
        return false;
      }
    }
    return true;
  }

  @computedFrom("sellableProductList")
  get dynamicProductCategoryList() {
    if (!this.store) {
      return [];
    }
    let set = new Set<ProductCategory>();
    for (let row of this.sellableProductList) {
      set.add(row.productCategory);
    }
    return [...set];
  }

  sellableError(productCategory: SellableProductCategory, product?: UIProduct) {
    if (this.store?.store.closed && this.store?.store.closed >= this.deliveryDate) {
      return this.i18n.tr("store.storeIsClosed");
    }

    if (!productCategory?.productCategoryCanBeSold) {
      return this.i18n.tr(product ? "store.productCanNotBeSold" : "store.productCategoryCanNotBeSold");
    }

    if (!product) {
      return;
    }

    if (!product.hasEnoughStock(product.orderedAmount, product.selectedPortion?.portion)) {
      return this.i18n.tr("store.outOfStock");
    }

    const storeProductCatalog = this.store?.storeProductCatalogList.find(pc => pc.productId === product?.id);

    /* no store product */
    if (!storeProductCatalog) {
      return this.i18n.tr("store.productCanNotBeSold");
    }

    /* not sellable in this period */
    if (storeProductCatalog.startDate > this.deliveryDate) {
      return this.i18n.tr("store.productCanBeSoldInFuture", { startDate: new FormatShortDateValueConverter().toView(storeProductCatalog.startDate, "noTime") } as {});
    }
    if (storeProductCatalog.endDate && storeProductCatalog.endDate < this.deliveryDate) {
      return this.i18n.tr("store.productNotInSale");
    }
  }

  @computedFrom("sellableProductList", "deliveryDate", "search")
  get specialProductList() {
    if (!this.store) {
      return [];
    }

    let productByProductId: { [productId: number]: SellableProduct; } = {};
    for (let p of this.sellableProductList) {
      productByProductId[p.product.id] = p;
    }

    let data: SellableProductCategory[] = [];
    for (let sp of this.store.specialProductList) {
      if (sp.specialProduct.startDate > this.deliveryDate) {
        continue;
      }
      if (sp.specialProduct.endDate < this.deliveryDate) {
        continue;
      }

      let pc: SellableProductCategory = {
        productCategoryCanBeSold: true,
        productCategorySalePeriodList: [],
        productCategory: {
          ...sp.specialProduct,
          id: 0, /* to defeat sellable error later */
          /* dummy garbage we aren't using */
          businessUnitId: 0,
          defaultPosProduct: "",
          defaultVatCategoryId: 0,
          weight: 0,
        },
        productSubCategoryList: [{
          productList: sp.productList.map(id => productByProductId[id])
            .filter(sp => {
              if (!sp) {
                return false;
              }
              const productName = this.translationUtil.localized(sp.product, "name", this.lang);
              return sp && (!this.search?.length || (productName && productName.toLowerCase().indexOf(this.search.toLowerCase()) >= 0));
            })
        }],
      };
      if (pc.productSubCategoryList[0].productList.length === 0) {
        continue;
      }
      data.push(pc);
    }

    return data;
  }

  @computedFrom("sellableProductList", "deliveryDate", "readyById", "search")
  get sellableProductCategoryList() {
    const store = this.store;
    if (!store) {
      return [];
    }
    const filteredSellableProductList = this.sellableProductList.filter(p => p.storeProductCatalog.startDate <= this.deliveryDate && (!p.storeProductCatalog.endDate || p.storeProductCatalog.endDate >= this.deliveryDate) &&
      !p.storeProductCatalog.discountGroupId || this.discountGroupList.find(dg => dg.discountGroup.id === p.storeProductCatalog.discountGroupId));
    /* group products by their product categories */
    let tmp = new Map<number, Map<number, SellableProduct[]>>();
    for (let product of filteredSellableProductList) {

      const productName = this.translationUtil.localized(product.product, "name", this.lang);
      if (this.search?.length && productName && productName.toLowerCase().indexOf(this.search.toLowerCase()) < 0) {
        continue;
      }

      if (!tmp.has(product.productCategory.id)) {
        tmp.set(product.productCategory.id, new Map());
      }

      let map = tmp.get(product.productCategory.id)!;
      if (!map.has(product.productSubCategory?.id || 0)) {
        map.set(product.productSubCategory?.id || 0, []);
      }

      let categorizedList = map.get(product.productSubCategory?.id || 0)!;
      categorizedList.push(product);
    }

    /* post-process */
    let list: SellableProductCategory[] = [];
    tmp.forEach((subcategoryMap, productCategoryId) => {
      const productCategory = store.productCategoryList.find(pc => pc.id === productCategoryId);
      if (!productCategory) {
        return;
      }

      const sellable = this.readyBy || (this.readyByList.length ? this.readyByList[0].time : "");
      const productCategoryCanBeSold = this.calculateProductCategoryCanBeSold(productCategory, this.deliveryDate, sellable);

      const dow = dayOfWeekList[store.today.getUTCDay()];
      const spc: SellableProductCategory = {
        productCategory,
        productCategoryCanBeSold,
        productCategorySalePeriodList: store.productCategorySalePeriodList.filter(pcsp => pcsp.productCategoryId == productCategory.id && pcsp.dayOfWeek === dow),
        productSubCategoryList: [],
      };

      subcategoryMap.forEach((productList, productSubCategoryId) => {
        let productSubCategory = store.productSubCategoryList.find(psc => psc.id === productSubCategoryId);
        if (productSubCategoryId && !productSubCategory) {
          return;
        }

        spc.productSubCategoryList.push({
          productSubCategory,
          productList,
        });
      });

      list.push(spc);
    });

    return list;
  }

  @computedFrom("store.store.paymentType")
  get storePaymentTypeList() {
    if (!this.store) {
      return [];
    }
    return this.store.store.paymentType.map(pt => ({
      id: pt,
      name: this.i18n.tr("PaymentType." + pt, {}),
    }));
  }

  async maybeSendOrder() {
    this.store = await this.client.publicStoreById({ id: this.store?.store.id });
    this.shoppingCart.store = this.store;

    if (!this.store) {
      return;
    }

    if (!this.readyBy) {
      this.notify.info("order.deliveryTimeNoLongerValid");
      return;
    }

    if (this.closed) {
      return;
    }

    if (!this.client.session) {
      try {
        let session = await this.client.accountRegister({
          storeId: this.store.store.id,
          authenticationType: "EMAIL",
          firstName: this.firstName || '',
          lastName: this.lastName || '',
          email: this.email,
          phone: this.phone,
        });
        this.client.session = session;
      }
      catch (error: any) {
        if (error.message === "server.emailExists") {
          this.notify.loginModal = true;
          this.notify.loginCallback = () => this.maybeSendOrder();
        }
        return;
      }
    }

    let orderRequest: OrderSendOrderRequest = {
      storeId: this.store.store.id,
      products: [],
      deliveryMethod: this.deliveryMethod,
      deliveryDate: this.deliveryDate,
      readyBy: this.readyBy,
      readyByDescription: this.readyByName,
      paymentType: this.paymentType,
      tableNumber: this.tableNumber,
      freeText: this.freeText,
      language: this.lang == "fi" ? "FI" : "EN",
    };
    localStorage.setItem("table-number", this.tableNumber || "");

    for (let shoppingCartProduct of this.shoppingCart.products) {
      const product = shoppingCartProduct.product;
      const productCategory = this.sellableProductCategoryList.find(c => c.productCategory.id === product.productCategoryId);
      if (!productCategory) {
        return;
      }
      product.productOrderError = this.sellableError(productCategory, product);

      if (product.productOrderError?.length) {
        return;
      }

      /* no products added */
      if (!shoppingCartProduct.amount) {
        continue;
      }

      const extras: OrderSendOrderRequestRowExtra[] = [];
      for (const [extraGroupId, extraId] of Object.entries(product.selectedSingleExtras)) {
        if (extraId) {
          extras.push({
            extraGroupId: parseInt(extraGroupId),
            extraId: extraId,
          });
        }
      }
      await this.maybeFetchDiscounts(false, false);
      for (const [extraGroupId, multiExtras] of Object.entries(product.selectedMultiExtras)) {
        for (const extraId of Object.values(multiExtras)) {
          if (extraId) {
            extras.push({
              extraGroupId: parseInt(extraGroupId),
              extraId: parseInt(extraId),
            });
          }
        }
      }
      orderRequest.products.push({
        productId: product.id,
        discountGroupRowId: this.discountGroupRowByProductId[product.id]?.id,
        quantity: shoppingCartProduct.amount,
        extras: extras,
        portionId: product.productPortionList[product.selectedPortionIdx]?.id,
      });
    }
    this.sendOrderWaiting = true;
    try {
      const orderErrors = await this.client.publicCheckOrder(orderRequest);

      for (const orderError of orderErrors) {
        for (let product of this.shoppingCart.products.filter(p => p.product.id === orderError.productId || p.product.productProductList.find(pp => pp.productId === orderError.productId))) {
          product.product.productOrderError = this.i18n.tr(orderError.error, { quantity: orderError.availableAmount } as {});
        }
      }
      if (orderErrors.length) {
        return;
      }

      /* Clear orderErrors */
      for (let product of this.shoppingCart.products) {
        product.product.productOrderError = "";
      }
      const res = await this.client.publicSendOrder(orderRequest);
      if (res.paymentType === "PrePaid") {
        let forward = await this.client.publicPaymentHosted({ orderId: res.id });
        this.notify.setForward(forward);
      } else {
        this.router.navigateToRoute("confirmation/show", { id: res.id });
      }
    }
    finally {
      this.sendOrderWaiting = this.notify.hasForward();
    }
  }

  productImage(product: UIProduct) {
    if (!this.store) {
      return undefined;
    }
    if (this.store.productImageExistenceList.indexOf(product.id) !== -1) {
      return this.client.publicProductImageUrl({ id: product.id, modifyTime: product.modifyTime });
    }
    if (this.store.productCategoryImageExistenceList.indexOf(product.productCategoryId) !== -1) {
      let productCategory = this.store.productCategoryList.find(pc => pc.id === product.productCategoryId);
      if (productCategory) {
        return this.client.publicProductCategoryImageUrl({ id: productCategory.id, modifyTime: productCategory.modifyTime });
      }
    }

    return undefined;
  }

  refresh() {
    if (this.shoppingCart) {
      this.shoppingCart.products = [...this.shoppingCart.products];
    }
  }

  copyProduct(idx: number) {
    if (!this.store) {
      return;
    }
    const newSellableProduct = Object.assign({}, this.sellableProductList[idx]);
    let storeProduct = this.store.productList.find(p => p.id == newSellableProduct.product.id);
    if (!storeProduct) {
      return;
    }
    newSellableProduct.product = this.createUIProduct(storeProduct, this.store);
    newSellableProduct.product.show = true;

    this.sellableProductList.splice(idx + 1, 0, newSellableProduct);
    this.sellableProductList = this.sellableProductList.map((p, i) => ({ ...p, idx: i }));

    signalToProducts(storeProduct.id, storeProduct.productType, this.store?.productProductList, this.signaler);

    return newSellableProduct;
  }

  showConfirm() {
    if (!this.store) {
      return;
    }

    if (this.store.store.tableNumber === 'REQUIRED' && !this.tableNumber) {
      this.adjustTableNumber(() => this.showConfirm());
      return;
    }

    /* Ensure that readyBy still exists -- if user idles too long, it may be that readyBy must be updated here */
    if (this.store.store.autoSelectFirstPossibleTime == "FIRST_AND_HIDE") {
      this.deliveryDate = this.deliveryDateList[0]?.id || dateCache(this.store.today);
      this.readyById = this.readyByList[0]?.id;
    }

    if (!this.readyBy) {
      this.adjustDesiredReadyTime(() => this.showConfirm());
      return;
    }

    if (!this.shoppingCart.products.length) {
      return;
    }

    if (this.store.store.minimumOrderAmount && this.store.store.minimumOrderAmount > this.shoppingCart.cartTotal) {
      this.notify.info("order.minimumOrderAmount", { amount: this.store.store.minimumOrderAmount, duration: 10000, classList: "notification-minimum-order" });
      return;
    }

    this.confirmModal = true;
  }
}
